import { DestroyRef, inject, Injectable } from '@angular/core';
import { filtreParentNode } from '@types_custom/subFiltreViewType';
import { BehaviorSubject, combineLatest, timer } from 'rxjs';
import {
  debounceTime,
  map,
  scan,
  shareReplay,
  startWith,
  throttleTime,
} from 'rxjs/operators';
import { LOCALSTORAGE } from '../enum/local-storage';
import { EventsService } from '../services/events.service';
import { EmplacementsStateService } from './emplacements-state.service';
import { ProductFamiliesTreeState } from './product-families-tree-state.service';
import { emplacementDocType } from '../db/schemas/emplacement.schema';
import {
  getBooleanFromLocalStorage,
  getFromLocalStorage,
  setToLocalStorage,
} from '../utils/localstorage-utils.service';
import {
  MapboxSelectionsStateService,
  MapboxSelectionType,
} from '../services/mapbox/mapbox-selection-state.service';
import { TagService } from '../services/tag/tag.service';
import { tagDocType } from '../db/schemas/tag.schema';
import { reglementationDocType } from '../db/schemas/reglementation.schema';
import { productpropertiesDocType } from '../db/schemas/productproperties.schema';
import { productfieldDocType } from '../db/schemas/productfields.schema';
import { fabricantDocType } from '../db/schemas/fabricant.schema';
import { interventionDocType } from '../db/schemas/intervention.schema';
import { typeFiltresDisplay } from '../components/filtre-template/typeFiltresDisplay';
import { auth } from '../services/nhost';
import { InterventionsStateService } from './interventions-state.service';
import { CommonStateService } from './commun-state.service';
import { ProductFamiliesType } from '@types_custom/ProductType';
import { isEmplacementInChoosenTree } from '../utils/emplacement/isEmplacementInChoosenTree';
import { beautifyFilterNameFunction } from '../utils/beautifyFilterNameFunction';
import { BatchData } from '../db/interfaces/BatchData';
import {
  saveActiveFiltersToStorage,
  removeActiveFiltersFromStorage,
} from './filter-storage.utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

let startGlobal = 0;

const CUSTOM_SELECTION_KEY = 'custom_selection';
const DOSSIER = 'dossier';
const INTERVENTION = 'intervention';
const FILTRE = 'filtre';
const FILTER_PREFIX = 'tag';

@Injectable({
  providedIn: 'root',
})
export class EmplacementFilterState {
  private destroyRef = inject(DestroyRef);
  private userId = auth.getUser()?.id;

  private isBusySubject$ = new BehaviorSubject<BusyType>({
    doingEmplacement: false,
    doingFilter: false,
  });
  isBusy$ = this.isBusySubject$.asObservable();

  private strictModeSubject$ = new BehaviorSubject<boolean>(
    getBooleanFromLocalStorage('strictMode')
  );
  strictMode$ = this.strictModeSubject$.asObservable();

  private getFiltersSubject$ = new BehaviorSubject<filtreParentNode[]>([]);
  getFilters$ = this.getFiltersSubject$.asObservable().pipe(
    map((e) => {
      return this.countCheckedFilters(e);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  getArchivedEmplacements$ = combineLatest([
    this.emplacementsState.emplacements$,
    this.tagState.getArchivedTagsIds$(),
  ]).pipe(
    map(([emplacements, archivedTagsId]) => {
      return this.getArchivedEmplacements(emplacements, archivedTagsId);
    })
  );

  sourceVanillaTreeLength$ = this.productTreeState.vanillaTree$.pipe(
    map((e) => e.length)
  );
  sourceChoosenTree$ = this.productTreeState.choosenTree$;
  getFilteredEmplacements$ = combineLatest([
    timer(4000),
    this.emplacementsState.emplacements$,
    this.getFilters$,
    this.sourceChoosenTree$,
    this.strictMode$,
    this.sourceVanillaTreeLength$,
  ]).pipe(
    map(
      ([
        ,
        emplacements,
        filters,
        choosenTree,
        strictMode,
        vanillaTreeLength,
      ]) => {
        if (choosenTree.length < 1) return [];
        if (filters.length < 1) return emplacements;
        return this.filtreEmplacements(
          filters,
          emplacements,
          choosenTree,
          strictMode,
          vanillaTreeLength
        );
      }
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private worker: Worker | null = null;

  constructor(
    private emplacementsState: EmplacementsStateService,
    private events: EventsService,
    private productTreeState: ProductFamiliesTreeState,
    private tagState: TagService,
    private mapboxSelectionState: MapboxSelectionsStateService,
    private interventionsState: InterventionsStateService,
    private commonState: CommonStateService
  ) {
    this.listenMapSelection();

    const observable$ = combineLatest([
      this.emplacementsState.batchEmplacements$.pipe(
        // On ne veut que les ids des emplacements
        scan((acc: Set<string>, batch: BatchData<emplacementDocType>) => {
          const currentIds = new Set(batch.data.map((item) => item.id));
          switch (batch.type) {
            case 'start':
              return currentIds;
            case 'batch':
            case 'complete':
              currentIds.forEach((id) => acc.add(id));
              return acc;
            default:
              return acc;
          }
        }, new Set<string>()),
        map((acc) => Array.from(acc)), // Convert the Set back to an Array for the downstream operators
        debounceTime(100)
      ),
      this.tagState.tags$,
      this.interventionsState.interventions$,
      this.sourceChoosenTree$,
      this.commonState.common$,
      this.events.getObservable('filtres:redo').pipe(startWith(null)),
    ]);

    observable$
      .pipe(
        throttleTime(100, undefined, { leading: true, trailing: true }),
        takeUntilDestroyed(this.destroyRef)
      )
      // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntilDestroyed is used
      .subscribe(
        ([
          emplacements,
          tags,
          interventions,
          choosenTree,
          { reglementations, properties, fields, fabricants },
          retrigger,
        ]) => {
          if (choosenTree.length < 1 || emplacements.length === 0) return;

          if (!this.worker) {
            this.setWorker();
          }

          this.callWorker(
            emplacements,
            tags,
            interventions,
            choosenTree,
            reglementations,
            properties,
            fields,
            fabricants
          );
        }
      );
  }

  private listenMapSelection() {
    this.mapboxSelectionState.mapboxSelections$
      .pipe(takeUntilDestroyed(this.destroyRef))
      // eslint-disable-next-line rxjs/no-ignored-subscription -- takeUntilDestroyed is used
      .subscribe((mapboxSelection) => {
        const data = this.getFiltersSubject$.getValue();
        this.setFiltersSubject(data, mapboxSelection);
      });
  }

  // Fonction appelée par le composant filtre pour mettre à jour les filtres
  updateFilterValues(
    filtres: filtreParentNode[],
    typeFiltres: typeFiltresDisplay
  ) {
    const currentFilters = this.getFiltersSubject$.getValue();
    let updatedFilters: filtreParentNode[] = [];

    if (typeFiltres === DOSSIER) {
      // Mettre à jour uniquement les filtres liés aux dossiers
      const nonDossierFilters = currentFilters.filter(
        (f) =>
          !f.key.startsWith(FILTER_PREFIX) &&
          !f.key.startsWith(CUSTOM_SELECTION_KEY)
      );
      updatedFilters = [...nonDossierFilters, ...filtres];
    } else if (typeFiltres === INTERVENTION) {
      // Mettre à jour uniquement les filtres liés aux interventions
      const nonInterventionFilters = currentFilters.filter(
        (f) => !f.key.startsWith(INTERVENTION)
      );
      updatedFilters = [...nonInterventionFilters, ...filtres];
    } else {
      // Mettre à jour uniquement les autres filtres (not dossier, not intervention)
      const dossierFilters = currentFilters.filter(
        (f) =>
          f.key.startsWith(FILTER_PREFIX) ||
          f.key.startsWith(INTERVENTION) ||
          f.key.startsWith(CUSTOM_SELECTION_KEY)
      );
      updatedFilters = [...dossierFilters, ...filtres];
    }

    const newFilters = this.doCountFiltersForSelection(updatedFilters);
    this.getFiltersSubject$.next(newFilters);

    const selectedFilters = this.getCheckedFiltersNode(newFilters);
    saveActiveFiltersToStorage(selectedFilters, this.userId);
  }

  simulateClickOnFilter(
    childKey: string,
    type: typeFiltresDisplay,
    uncheckOthers = false
  ) {
    const filters = this.getFiltersSubject$.getValue();

    const updatedFilters = filters.map((filter) => {
      if (filter.children) {
        filter.children = filter.children.map((child) => {
          if (child.key === childKey) {
            filter.isExpanded = true;
            child.checked = true;
          } else if (uncheckOthers) {
            child.checked = false;
          }
          return child;
        });
      }
      return filter;
    });

    this.updateFilterValues(updatedFilters, type);
  }

  setWorker() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(
        new URL('./../filters.worker', import.meta.url),
        {
          type: 'module',
        }
      );

      this.worker.onerror = (data) => {
        console.log('WORKER ERROR : ', data);
      };

      this.worker.onmessage = ({ data }: { data: filtreParentNode[] }) => {
        const mapboxSelection = this.mapboxSelectionState.getMapboxSelections();
        const endGlobal = performance.now();

        console.log(
          'populateFiltres: end, duration: ' + (endGlobal - startGlobal)
        );
        this.setFiltersSubject(data, mapboxSelection);

        this.isBusySubject$.next({
          ...this.isBusySubject$.getValue(),
          doingFilter: false,
        });
      };
    }
  }

  public callWorker(
    emplacements: string[],
    tags: tagDocType[],
    interventions: interventionDocType[],
    choosenTree: ProductFamiliesType[],
    reglementations: reglementationDocType[],
    productproperties: productpropertiesDocType[],
    productfields: productfieldDocType[],
    fabricants: fabricantDocType[]
  ) {
    this.isBusySubject$.next({
      ...this.isBusySubject$.getValue(),
      doingFilter: true,
    });
    startGlobal = performance.now();

    const overrideDurations = getFromLocalStorage(
      LOCALSTORAGE.OVERRIDE_DURATIONS,
      true
    );

    const overrideClasses = ['1', '2', '3'].reduce((acc: any, e) => {
      if (getFromLocalStorage('age_limit_' + e))
        acc[e] = getFromLocalStorage('age_limit_' + e);
      return acc;
    }, {});

    // @TODO : Ne prendre que les products properties pour les familles du choosenTree
    if (typeof Worker !== 'undefined' && this.worker) {
      const message = {
        emplacements: emplacements,
        choosenTree: choosenTree.map((e) => e.id),
        productproperties,
        productfields,
        reglementations,
        fabricants,
        tags,
        overrideDurations,
        overrideClasses,
        interventions,
        checkedFilters: {},
        userId: auth.getUser()?.id,
      };
      this.worker.postMessage(message);
    }
  }

  private createCustomSelectionChild(
    mapboxSelection: MapboxSelectionType,
    isSelected: boolean
  ): filtreParentNode {
    return {
      key: CUSTOM_SELECTION_KEY,
      libelle:
        mapboxSelection.endPos && mapboxSelection.startPos
          ? $localize`Sélection depuis la carte`
          : $localize`Sélection personnalisée`,
      count: mapboxSelection.emplacementIds.length || 0,
      checked: isSelected,
      isExpanded: false,
      children: [],
      visibility: true,
      show: true,
      emplacements: mapboxSelection.emplacementIds,
      type: CUSTOM_SELECTION_KEY,
    };
  }

  private setFiltersSubject(
    data: filtreParentNode[],
    mapboxSelection: MapboxSelectionType
  ) {
    const indexFilterCustomSelection = data.findIndex(
      (e) => e.key === CUSTOM_SELECTION_KEY
    );
    const hasCustomSelection = indexFilterCustomSelection !== -1;
    const isSelected =
      (hasCustomSelection &&
        data[indexFilterCustomSelection]?.children?.some((e) => e.checked)) ??
      false;
    const commonProperties = {
      isExpanded: true,
      opened: true,
      show: true,
      visibility: true,
    };

    if (hasCustomSelection) {
      if (mapboxSelection.emplacementIds.length > 0) {
        data[indexFilterCustomSelection] = {
          ...data[indexFilterCustomSelection],
          ...commonProperties,
          children: [
            this.createCustomSelectionChild(mapboxSelection, isSelected),
          ],
        };
      } else {
        data[indexFilterCustomSelection] = {
          ...data[indexFilterCustomSelection],
          ...commonProperties,
          children: [],
        };
      }
    } else if (mapboxSelection.emplacementIds.length > 0) {
      data.push({
        key: CUSTOM_SELECTION_KEY,
        type: DOSSIER,
        children: [this.createCustomSelectionChild(mapboxSelection, false)],
        ...commonProperties,
      });
    }

    this.updateCheckedFilters(data);
  }

  private updateCheckedFilters(data: filtreParentNode[]) {
    const checkedFilters = this.getCheckedFilters(
      this.getFiltersSubject$.getValue()
    );

    Object.keys(checkedFilters).forEach((key) => {
      const index = data.findIndex((e) => e.key === key);
      if (index !== -1) {
        checkedFilters[key].forEach((value) => {
          const childIndex = data[index].children?.findIndex(
            (e) => e.key === value
          );
          if (childIndex !== -1 && childIndex !== undefined) {
            data[index].isExpanded = true;
            const proxy = data[index].children;
            if (proxy?.[childIndex]) {
              proxy[childIndex] = { ...proxy[childIndex], checked: true };
            }
          }
        });
      }
    });

    // Call doCountFiltersForSelection to update counts for the current selection
    const updatedData = this.doCountFiltersForSelection(data);

    this.getFiltersSubject$.next(updatedData);
  }

  filtreEmplacements(
    filters: filtreParentNode[],
    emplacements: emplacementDocType[],
    choosenTree: ProductFamiliesType[],
    strictFilters: boolean = false,
    vanillaTreeLength: number
  ): emplacementDocType[] {
    const archivedTagsId = this.tagState.getArchivedTagsIds();
    if (!choosenTree.length) {
      return this.filterOutArchivedTagsFromEmplacements(
        emplacements,
        archivedTagsId
      );
    }

    const isAllSelected = choosenTree.length === vanillaTreeLength;
    // Filtrer les emplacements en vérifiant chaque emplacement contre tous les filtres actifs
    const filteredEmplacements = emplacements.filter((emplacement) => {
      if (
        !isAllSelected &&
        !isEmplacementInChoosenTree(emplacement, choosenTree)
      ) {
        return false;
      }

      return filters.every((filter) => {
        // Si le filtre n'a pas d'enfants ou aucun enfant n'est coché, passer ce filtre
        if (
          !filter.children?.length ||
          !filter.children.some((child) => child.checked)
        ) {
          return true;
        }

        if (strictFilters) {
          // Mode Strict: Vérifier que l'emplacement satisfait à tous les enfants cochés du filtre
          return filter.children.every(
            (child) =>
              !child.checked ||
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              (child.checked && child.emplacements?.includes(emplacement.id))
          );
        }
        // Mode non strict: Vérifier si l'emplacement satisfait à au moins un des enfants cochés du filtre
        return filter.children.some(
          (child) =>
            child.checked && child.emplacements?.includes(emplacement.id)
        );
      });
    });

    return this.filterOutArchivedTagsFromEmplacements(
      filteredEmplacements,
      archivedTagsId
    );
  }

  filterOutArchivedTagsFromEmplacements(
    emplacements: emplacementDocType[],
    archivedTagsId: string[]
  ): emplacementDocType[] {
    if (!archivedTagsId.length) {
      return emplacements;
    }

    return emplacements.filter((emplacement) => {
      return !emplacement.emplacement_tags.every((emplacement_tag) =>
        archivedTagsId.includes(emplacement_tag.id_tag)
      );
    });
  }

  getArchivedEmplacements(
    emplacements: emplacementDocType[],
    archivedTagsId: string[]
  ): emplacementDocType[] {
    if (!archivedTagsId.length) {
      return [];
    }

    return emplacements.filter((emplacement) => {
      return emplacement.emplacement_tags.every((emplacement_tag) =>
        archivedTagsId.includes(emplacement_tag.id_tag)
      );
    });
  }

  razFilter(typeDossier: typeFiltresDisplay | typeFiltresDisplay[]) {
    const doTheThing = (key: filtreParentNode) => {
      const valuesKey = key.children;
      if (valuesKey) {
        for (const keyEl of valuesKey) {
          keyEl.checked = false;
        }
      }
      key.valueFilter = '';
      key.isExpanded = false;
    };

    const isMatchingType = (keyType: string, type: typeFiltresDisplay) => {
      if (type === DOSSIER) {
        return keyType !== 'bounds' && keyType.startsWith(FILTER_PREFIX);
      } else if (type === INTERVENTION) {
        return keyType !== 'bounds' && keyType.startsWith(INTERVENTION);
      }
      return (
        keyType !== 'bounds' &&
        !keyType.startsWith(FILTER_PREFIX) &&
        !keyType.startsWith(INTERVENTION)
      );
    };

    const typesDossier = Array.isArray(typeDossier)
      ? typeDossier
      : [typeDossier];

    removeActiveFiltersFromStorage(this.userId);

    const filters = this.getFiltersSubject$
      .getValue()
      .map((key: filtreParentNode) => {
        for (const type of typesDossier) {
          if (isMatchingType(key.key, type)) {
            doTheThing(key);
            break;
          }
        }
        return key;
      });

    this.getFiltersSubject$.next([...filters]);
  }

  razAllFilters() {
    this.razFilter([FILTRE, DOSSIER, INTERVENTION]);
  }

  countCheckedFilters(filters: filtreParentNode[], isDossier = false) {
    filters.forEach((key: filtreParentNode) => {
      let count = 0;
      if (key.children)
        key.children.forEach((subKey: filtreParentNode) => {
          if (subKey.checked) {
            count++;
          }
        });
      key.countChecked = count;
    });
    return filters;
  }

  getCheckedFilters(
    filters: filtreParentNode[] | null = null
  ): Record<string, string[]> {
    if (!filters) filters = this.getFiltersSubject$.getValue();
    return filters.reduce((acc: any, key: filtreParentNode) => {
      if (key.children)
        key.children.forEach((subKey: filtreParentNode) => {
          if (subKey.checked) {
            if (!acc[key.key]) acc[key.key] = [];
            acc[key.key].push(subKey.key);
          }
        });
      return acc;
    }, {});
  }

  getCheckedFiltersNode(
    filters: filtreParentNode[]
  ): Record<string, filtreParentNode[]> {
    return filters.reduce((acc: any, key: filtreParentNode) => {
      if (key.children)
        key.children.forEach((subKey: filtreParentNode) => {
          if (subKey.checked) {
            if (!acc[key.key]) acc[key.key] = [];
            acc[key.key].push(subKey);
          }
        });
      return acc;
    }, {});
  }

  getCheckedFiltersForCsv(addDefaultLine = true) {
    const checkedFilters = this.getCheckedFilters();
    const filtersCsv = Object.keys(checkedFilters).reduce(
      (acc: any[] = [], key) => {
        const line = {
          [$localize`Filtre(s) activé(s)`]: beautifyFilterNameFunction(key),
          [$localize`Valeur(s) utilisée(s)`]: checkedFilters[key].join(', '),
        };
        acc.push(line);
        return acc;
      },
      []
    );
    if (filtersCsv.length === 0 && addDefaultLine) {
      filtersCsv.push({
        [$localize`Filtre(s) activé(s)`]: $localize`Aucun`,
        [$localize`Valeur(s) utilisée(s)`]: '',
      });
    }
    return filtersCsv;
  }

  doCountFiltersForSelection(newFilters: filtreParentNode[]) {
    const strictMode = this.strictModeSubject$.getValue();
    const checkedFilters = this.getCheckedFilters(newFilters);

    const emplacementForCheckedFilters = Object.keys(checkedFilters).reduce(
      (acc: Set<string> | null, key: string) => {
        const filter = newFilters.find((f) => f.key === key);
        if (!filter) return acc;

        if (strictMode) {
          // Mode strict: Intersection des emplacements des enfants
          const emplacements = filter.children
            ?.filter((c) => c.checked)
            .reduce((acc, c) => {
              if (!c.emplacements) return acc;
              if (acc === null) return new Set(c.emplacements);
              return new Set(c.emplacements.filter((e) => acc.has(e)));
            }, acc);

          return acc === null
            ? new Set<string>(emplacements)
            : new Set<string>(
                [...(emplacements ?? [])].filter((e) => acc.has(e))
              );
        }

        // Mode non strict: Union des emplacements des enfants
        const emplacements = filter.children
          ?.filter((c) => checkedFilters[key].includes(c.key))
          .flatMap((c) => c.emplacements)
          .filter((e) => acc === null || acc.has(e));

        return acc === null
          ? new Set<string>(emplacements)
          : new Set<string>(
              [...(emplacements ?? [])].filter((e) => acc.has(e))
            );
      },
      null
    );

    const getChildrens = (parent: filtreParentNode): filtreParentNode[] => {
      const childrens = parent.children?.map((child): filtreParentNode => {
        const countForActualSelection = child.emplacements?.filter(
          (e: string) => emplacementForCheckedFilters?.has(e)
        ).length;

        return {
          ...child,
          countForActualSelection: countForActualSelection,
        };
      });

      // Il faut trier les enfants par ordre décroissant de countForActualSelection, puis par ordre alphabétique
      childrens?.sort((a, b) => {
        const countDiff =
          (b.countForActualSelection ?? 0) - (a.countForActualSelection ?? 0);
        return countDiff === 0
          ? (a.designation ?? '').localeCompare(b.designation ?? '')
          : countDiff;
      });

      return childrens ?? [];
    };

    const newNewFilters: filtreParentNode[] = newFilters.map((parent) => {
      return {
        ...parent,
        children: getChildrens(parent),
      };
    });

    return newNewFilters;
  }

  toggleStrictMode() {
    const newValue = !this.strictModeSubject$.getValue();
    setToLocalStorage('strictMode', newValue);
    this.strictModeSubject$.next(newValue);
  }
}

interface BusyType {
  doingFilter: boolean;
  doingEmplacement: boolean;
}
